@import '../../shared/settings';

.pagination-navigation {
    padding: 32px 0 36px;
    text-align: center;
    font-size: 14px;

    span,
    a {
        display: inline-block;
        border-radius: 36px;
    }

    a,
    .gap {
        padding: 0 10px;
        min-width: 16px;
        height: 36px;
        line-height: 36px;
        color: $dark-grey;
    }

    a {
        border: 1px solid $light-grey;
        background-color: white;
        font-weight: 500;

        & + a {
            margin-left: 8px;
        }

        &:hover {
            color: $brand;
        }

        &.active {
            border: 1px solid $brand;
            background-color: $brand;
            color: white;

            &:hover {
                color: white;
            }
        }

        &.previous,
        &.next {
            border: 1px solid $light-grey;
            background: white;

            svg {
                fill: $dark-grey;
            }

            &:hover {
                transition: border-color $fast, background-color $fast;
                border-color: $brand;
                background-color: $brand;

                svg {
                    transition: fill $fast;
                    fill: white;
                }
            }
        }
    }

    span {
        &.previous,
        &.next {
            border: 1px solid rgba($light-grey, 0.75);
            background: rgba(white, 0.75);

            svg {
                fill: rgba($grey, 0.75);
            }
        }
    }

    .previous,
    .next {
        position: relative;
        top: 6px;
        padding: 0;
        width: 24px;
        height: 24px;
        vertical-align: top;
        box-sizing: border-box;

        svg {
            position: absolute;
            top: 7px;
            transition: fill $fast;
            width: 5px;
            height: 8px;
        }
    }

    .previous {
        margin-right: 12px;

        svg {
            left: 8px;
            transform: rotate(180deg);
        }
    }

    .next {
        margin-left: 12px;

        svg {
            right: 8px;
        }
    }

    select {
        display: inline-block;
        border-radius: 36px;
        border-color: $light-grey;
        background-position: right 18px center;
        padding: 0 32px 0 24px;
        height: 36px;
        line-height: 34px;
        font-weight: var(--medium);
    }
}
